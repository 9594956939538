@font-face {
  font-family: 'FilsonProRegular';
  src: url('../fonts/FilsonProRegular.eot');
  src: url('../fonts/FilsonProRegular.eot') format('embedded-opentype'), url('../fonts/FilsonProRegular.woff2') format('woff2'), url('../fonts/FilsonProRegular.woff') format('woff'), url('../fonts/FilsonProRegular.ttf') format('truetype'), url('../fonts/FilsonProRegular.svg#FilsonProRegular') format('svg');
}
@font-face {
  font-family: 'FilsonProMedium';
  src: url('../fonts/FilsonProMedium.eot');
  src: url('../fonts/FilsonProMedium.eot') format('embedded-opentype'), url('../fonts/FilsonProMedium.woff2') format('woff2'), url('../fonts/FilsonProMedium.woff') format('woff'), url('../fonts/FilsonProMedium.ttf') format('truetype'), url('../fonts/FilsonProMedium.svg#FilsonProMedium') format('svg');
}
body {
  background-color: #050814;
  color: #fff;
  font-family: 'FilsonProRegular', Arial, sans-serif;
  font-size: 16px;
}
.container {
  max-width: 100%;
}
.wrapper {
  max-width: 1100px;
}
.wrapper-checkout {
  max-width: 900px;
}
.f-size-20px {
  font-size: 20px;
}
h1,
h2,
h3,
h4,
h5 {
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.55);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
a {
  color: #fff;
}
a:hover {
  color: #9bcfdd;
  text-decoration: underline;
}
input[type="radio"] {
  cursor: pointer;
}
.gray {
  color: #868e9d;
}
.red {
  color: red;
}
.logo {
  max-width: 250px;
  margin-top: 35px;
}
.customTextbox {
  background-color: #ececec;
  border-color: #868e9d;
}
.custom-button {
  background-color: #ffc529;
  color: #03081a;
  border: 0;
  border-radius: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: 'FilsonProMedium', Arial, sans-serif;
}
.custom-button:hover {
  background-color: #ffa700;
  color: #03081a;
  text-decoration: none;
}
.custom-button:disabled {
  background-color: darkgray;
}
.form-select,
.form-select:focus {
  background-color: #050814;
  color: #fff !important;
}
.custom-textbox,
.custom-textbox:focus {
  background-color: #050814;
  color: #fff;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.55);
}
.pdf-icon {
  margin-top: -3px;
  width: 50px;
  height: 50px;
}
.pdf-button {
  display: inline-block;
  max-width: 300px;
}
/* Increase radio button's and checkbox size */
input[type='checkbox'],
input[type='radio'] {
  width: 20px;
  height: 20px;
}
.isHidden {
  visibility: hidden;
}
.margin-fees {
  margin-left: 37px;
}
.checkout-radio {
  top: 5px;
  position: relative;
  margin-right: 15px;
}
.blank-button {
  color: #9bcfdd;
  background-color: transparent;
  border: 0;
}
.checkoutEventName {
  color: #ffc529;
}
.custom-grey {
  color: rgba(255, 255, 255, 0.5);
}
.min-height-slot-description {
  min-height: 20px;
}
.non-uppercase {
  text-transform: initial !important;
}
@media (max-width: 768px) {
  .min-height-slot-description {
    min-height: 20px;
  }
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.7rem;
  }
}